<template>
  <div>
    <!-- ***** Welcome Area Start ***** -->
    <section id="home" class="section welcome-area bg-overlay overflow-hidden d-flex align-items-center">
      <div class="container">
        <div class="row align-items-center">
          <!-- Welcome Intro Start -->
          <div class="col-12 col-md-7 col-lg-6">
            <div class="welcome-intro">
              <h2 class="text-white text-uppercase">Don’t Let your assets sit idle</h2>
              <p class="text-white my-4">
                Earn Relai is one of the world leading staking companies offering the best staking rewards and crypto growth tools. We are currently staking over 200 yield bearing assets in our pools with an average APY of 46.15%
              </p>
              <!-- Store Buttons -->
              <div class="button-group store-buttons d-flex">
                <a href="/desktop" target="_self" class="btn btn-bordered mt-4">Go to Wallet</a>
                <!--                            <a href="#">-->
                <!--                                <img src="../../assets/parent/img/icon/google-play.png" alt="">-->
                <!--                            </a>-->
                <!--                            <a href="#">-->
                <!--                                <img src="../../assets/parent/img/icon/app-store.png" alt="">-->
                <!--                            </a>-->
              </div>
              <span class="d-inline-block text-white fw-3 font-italic mt-3">* Best viewed on mobile devices</span>
            </div>
          </div>
          <div class="col-12 col-md-5 col-lg-6">
            <!-- Welcome Thumb -->
            <div class="welcome-thumb mx-auto" data-aos="fade-left" data-aos-delay="500" data-aos-duration="1000">
              <img src="../../assets/parent/img/welcome/welcome-mockup.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <!-- Shape Bottom -->
      <div class="shape-bottom">
        <svg viewBox="0 0 1920 310" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="svg replaced-svg">
          <!--                <title>sApp Shape</title>-->
          <!--                <desc>Created with Sketch</desc>-->
          <defs></defs>
          <g id="sApp-Landing-Page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="sApp-v1.0" transform="translate(0.000000, -554.000000)" fill="#FFFFFF">
              <path d="M-3,551 C186.257589,757.321118 319.044414,856.322454 395.360475,848.004007 C509.834566,835.526337 561.525143,796.329212 637.731734,765.961549 C713.938325,735.593886 816.980646,681.910577 1035.72208,733.065469 C1254.46351,784.220361 1511.54925,678.92359 1539.40808,662.398665 C1567.2669,645.87374 1660.9143,591.478574 1773.19378,597.641868 C1848.04677,601.75073 1901.75645,588.357675 1934.32284,557.462704 L1934.32284,863.183395 L-3,863.183395" id="sApp-v1.0"></path>
            </g>
          </g>
        </svg>
      </div>
    </section>
    <!-- ***** Welcome Area End ***** -->

    <!-- ***** Counter Area Start ***** -->
    <section class="section counter-area ptb_50">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-5 col-sm-3 single-counter text-center">
            <div class="counter-inner p-3 p-md-0">
              <!-- Counter Item -->
              <div class="counter-item d-inline-block mb-3">
                <span class="counter fw-7">10</span><span class="fw-7">M</span>
              </div>
              <h5>Users</h5>
            </div>
          </div>
          <div class="col-5 col-sm-3 single-counter text-center">
            <div class="counter-inner p-3 p-md-0">
              <!-- Counter Item -->
              <div class="counter-item d-inline-block mb-3">
                <span class="counter fw-7">23</span><span class="fw-7">K</span>
              </div>
              <h5>Shipped</h5>
            </div>
          </div>
          <div class="col-5 col-sm-3 single-counter text-center">
            <div class="counter-inner p-3 p-md-0">
              <!-- Counter Item -->
              <div class="counter-item d-inline-block mb-3">
                <span class="counter fw-7">12</span><span class="fw-7">B+</span>
              </div>
              <h5>Assets</h5>
            </div>
          </div>
          <div class="col-5 col-sm-3 single-counter text-center">
            <div class="counter-inner p-3 p-md-0">
              <!-- Counter Item -->
              <div class="counter-item d-inline-block mb-3">
                <span class="counter fw-7">1</span><span class="fw-7">B+</span>
              </div>
              <h5>Auto Stake</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ***** Counter Area End ***** -->

    <!--        <section class="section download-area overlay-dark custom ptb_100" :style="{backgroundImage: 'url('+coinsImg+')'}">-->
    <!--            <div class="container">-->
    <!--                <div class="row justify-content-center">-->
    <!--                    <div class="col-12 col-md-10 col-lg-9">-->
    <!--                        &lt;!&ndash; Download Text &ndash;&gt;-->
    <!--                        <div class="download-text text-center" style="height: 150px">-->

    <!--                        </div>-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </section>-->

    <!-- ***** Features Area Start ***** -->
    <section id="features" class="section features-area style-two overflow-hidden ptb_50">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8">
            <!-- Section Heading -->
            <div class="section-heading text-center">
                            <span class="d-inline-block rounded-pill shadow-sm fw-5 px-4 py-2 mb-3">
                                <i class="far fa-lightbulb text-primary mr-1"></i>
                                <span class="text-primary">Premium</span>
                                Features
                            </span>
              <h2>What Makes EarnRelai Different?</h2>
              <p class="d-none d-sm-block mt-4">
                Here are some premium features you should look out for
              </p>
              <p class="d-block d-sm-none mt-4">
                Here are some premium features you should look out for
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4 res-margin">
            <!-- Image Box -->
            <div class="image-box text-center icon-1 p-5 wow fadeInLeft" data-wow-delay="0.4s">
              <!-- Featured Image -->
              <div class="featured-img mb-3">
                <img class="avatar-sm" src="../../assets/parent/img/icon/featured-img/layers.png" alt="">
              </div>
              <!-- Icon Text -->
              <div class="icon-text">
                <h3 class="mb-2">MORE ASSETS. MORE REWARDS</h3>
                <p>
                  Earn rewards with as little as $1 in crypto; The more you hold the more you earn
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 res-margin">
            <!-- Image Box -->
            <div class="image-box text-center icon-1 p-5 wow fadeInUp" data-wow-delay="0.2s">
              <!-- Featured Image -->
              <div class="featured-img mb-3">
                <img class="avatar-sm" src="../../assets/parent/img/icon/featured-img/speak.png" alt="">
              </div>
              <!-- Icon Text -->
              <div class="icon-text">
                <h3 class="mb-2">EARN AUTOMATICALLY </h3>
                <p>
                  Put your crypto to work. Rewards appear in your account periodically, depending on the asset
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <!-- Image Box -->
            <div class="image-box text-center icon-1 p-5 wow fadeInRight" data-wow-delay="0.4s">
              <!-- Featured Image -->
              <div class="featured-img mb-3">
                <img class="avatar-sm" src="../../assets/parent/img/icon/featured-img/lock.png" alt="">
              </div>
              <!-- Icon Text -->
              <div class="icon-text">
                <h3 class="mb-2">AUTO STAKING</h3>
                <p>
                  Lock in any crypto asset you own for 30 days and in return get staking rewards. Seamless one click staking with minimum requirements and maximum returns
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ***** Features Area End ***** -->

    <!-- ***** Service Area Start ***** -->
    <section class="section service-area bg-gray overflow-hidden ptb_100">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-12 col-lg-6 order-2 order-lg-1">
            <!-- Service Text -->
            <div class="service-text pt-4 pt-lg-0">
              <h2 class="text-capitalize mb-4">What is staking?</h2>
              <!-- Service List -->
              <ul class="service-list">
                <!-- Single Service -->
                <li class="single-service media py-2">
                  <div class="service-icon pr-4">
                    <span><i class="fab fa-buffer"></i></span>
                  </div>
                  <div class="service-text media-body">
                    <p>
                      The first thing you have to know about staking is staking/staking rewards aren’t investments so there is ZERO risk. Crypto staking is a process used to verify cryptocurrency transactions. It involves committing holdings to support a blockchain network and confirm the transactions. It also allows participants to earn passive income on their holdings…. this is because the blockchain puts your holding to work
                    </p>
                  </div>
                </li>
                <!-- Single Service -->
                <li class="single-service media py-2">
                  <div class="service-icon pr-4">
                    <span><i class="fas fa-brush"></i></span>
                  </div>
                  <div class="service-text media-body">
                    <p>
                      Earn passive income with crypto. Safe and secure staking. Up to 60% APY on all your assets, risk free…..Earn more by holding assets that generate rewards. We’re adding more assets all the time too
                    </p>
                  </div>
                </li>
                <!-- Single Service -->
                <li class="single-service media py-2">
                  <div class="service-icon pr-4">
                    <span><i class="fas fa-burn"></i></span>
                  </div>
                  <div class="service-text media-body">
                    <p>
                      Staking your own crypto is a challenge for most investors. To stake on your own requires running a node on your own hardware, syncing it to the blockchain, and funding the node with enough cryptocurrency to meet minimum thresholds, including providing a sizable deposit and bond. On Earn Relai, we do all this for you. While earn relai stakes, generates and signs blocks on your behalf, you retain full ownership of your tokens and earn rewards
                    </p>
                  </div>
                </li>
                <!-- Single Service -->
                <li class="single-service media py-2">
                  <div class="service-icon pr-4">
                    <span><i class="fas fa-lock"></i></span>
                  </div>
                  <div class="service-text media-body">
                    <p>
                      We Don’t Compromise on Security. User security is our top priority Never reveal your recovery seeds to anyone including RelaiEarn employees. We will never ask you about it
                    </p>
                  </div>
                </li>
              </ul>
              <a href="/desktop" target="_self" class="btn btn-bordered mt-4">Go to Wallet</a>
            </div>
          </div>
          <div class="col-12 col-lg-4 order-1 order-lg-2 d-none d-lg-block">
            <!-- Service Thumb -->
            <div class="service-thumb mx-auto">
              <img src="../../assets/parent/img/features/thumb-2.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ***** Service Area End ***** -->


    <!-- ***** Work Area Start ***** -->
    <section class="section work-area bg-overlay overflow-hidden ptb_100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8">
            <!-- Work Content -->
            <div class="work-content text-center">
              <h2 class="text-white">Get started in just a few minutes</h2>
              <!--                        <p class="d-none d-sm-block text-white my-3 mt-sm-4 mb-sm-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>-->
              <!--                        <p class="d-block d-sm-none text-white my-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p>-->
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12 col-md-4">
            <!-- Single Work -->
            <div class="single-work text-center p-3">
              <!-- Work Icon -->
              <div class="work-icon">
                <img class="avatar-md" src="../../assets/parent/img/icon/work/download.png" alt="">
              </div>
              <h3 class="text-white py-3">Sign up</h3>
              <p class="text-white">
                Get started with your referees link, deposit any asset and securely store or stake it in your Earn Relai wallet with just a few clicks
              </p>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <!-- Single Work -->
            <div class="single-work text-center p-3">
              <!-- Work Icon -->
              <div class="work-icon">
                <img class="avatar-md" src="../../assets/parent/img/icon/work/settings.png" alt="">
              </div>
              <h3 class="text-white py-3">Deposit</h3>
              <p class="text-white">
                Quickly generate a unique address on all your assets to top up your Earn Relai wallet and get started
              </p>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <!-- Single Work -->
            <div class="single-work text-center p-3">
              <!-- Work Icon -->
              <div class="work-icon">
                <img class="avatar-md" src="../../assets/parent/img/icon/work/app.png" alt="">
              </div>
              <h3 class="text-white py-3">Referral</h3>
              <p class="text-white">
                As our valued user you earn a percentage
                anytime a new user registers and deposits with your referral link
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ***** Work Area End ***** -->

    <!-- ***** FAQ Area Start ***** -->
    <section class="section faq-area style-two ptb_100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8">
            <!-- Section Heading -->
            <div class="section-heading text-center">
              <h2 class="text-capitalize">Frequently asked questions</h2>
              <!--                        <p class="d-none d-sm-block mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>-->
              <!--                        <p class="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p>-->
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12">
            <!-- FAQ Content -->
            <div class="faq-content">
              <!-- sApp Accordion -->
              <div class="accordion" id="sApp-accordion">
                <div class="row justify-content-center">
                  <div class="col-12 col-md-6">
                    <!-- Single Card -->
                    <div class="card border-0">
                      <!-- Card Header -->
                      <div class="card-header bg-inherit border-0 p-0">
                        <h2 class="mb-0">
                          <button class="btn px-0 py-3" type="button">
                            Staking rewards
                          </button>
                        </h2>
                      </div>
                      <!-- Card Body -->
                      <div class="card-body px-0 py-3">
                        Staking rewards are a new class of rewards available for eligible relai customers. Learn more about how Proof of Stake protocols work, how earn relai can help you earn rewards, who is eligible for rewards, and more.
                      </div>
                    </div>
                    <!-- Single Card -->
                    <div class="card border-0">
                      <!-- Card Header -->
                      <div class="card-header bg-inherit border-0 p-0">
                        <h2 class="mb-0">
                          <button class="btn px-0 py-3" type="button">
                            How does staking work?
                          </button>
                        </h2>
                      </div>
                      <!-- Card Body -->
                      <div class="card-body px-0 py-3">
                        Staking lets you earn income with your crypto by contributing to the Proof of Stake (PoS) network of a particular asset. When you stake your crypto, you make the underlying blockchain of that asset more secure and more efficient. And in exchange, you get rewarded with more assets from the network. To generate staking rewards on a Proof of Stake blockchain, a node has to designate a certain amount of tokens on the network as a stake (similar to a security deposit). The chance of that node being chosen to validate the next block is directly proportional to the number of tokens being staked. If the node successfully validates a block, it is awarded the staking reward, similar to a miner being rewarded in Proof of Work chains. Validators lose part of their stake if they approve a fraudulent transaction — this incentivizes them to only approve valid transactions.
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <!-- Single Card -->
                    <div class="card border-0">
                      <!-- Card Header -->
                      <div class="card-header bg-inherit border-0 p-0">
                        <h2 class="mb-0">
                          <button class="btn px-0 py-3" type="button">
                            How do I earn staking rewards ?
                          </button>
                        </h2>
                      </div>
                      <!-- Card Body -->
                      <div class="card-body px-0 py-3">
                        Today, staking your own crypto is a challenge for most investors. To stake on your own requires running a node on your own hardware, syncing it to the blockchain, and funding the node with enough cryptocurrency to meet minimum thresholds, including providing a sizable deposit and bond. On Earn Relai, we do all this for you. While earn relai stakes, generates and signs blocks on your behalf, you retain full ownership of your tokens and earn rewards.
                      </div>
                    </div>
                    <!-- Single Card -->
                    <div class="card border-0">
                      <!-- Card Header -->
                      <div class="card-header bg-inherit border-0 p-0">
                        <h2 class="mb-0">
                          <button class="btn px-0 py-3" type="button">
                            Who is eligible to stake on Earn Relai?
                          </button>
                        </h2>
                      </div>
                      <!-- Card Body -->
                      <div class="card-body px-0 py-3">
                        To stake on Earn Relai, you must have up to $1000 to start staking.
                      </div>
                    </div>
                    <div class="card border-0">
                      <!-- Card Header -->
                      <div class="card-header bg-inherit border-0 p-0">
                        <h2 class="mb-0">
                          <button class="btn px-0 py-3" type="button">
                            Do I have to buy my crypto on Earn Relai to earn staking rewards?
                          </button>
                        </h2>
                      </div>
                      <!-- Card Body -->
                      <div class="card-body px-0 py-3">
                        No. Eligible customers earn staking rewards on any eligible assets they hold on any wallet, regardless of where they purchase it. You are not required to purchase on Earn Relai to earn staking rewards — you can send assets from an external wallet to your Earn Relai staking wallet for free.
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-md-6">
                    <!-- Single Card -->
                    <div class="card border-0">
                      <!-- Card Header -->
                      <div class="card-header bg-inherit border-0 p-0">
                        <h2 class="mb-0">
                          <button class="btn px-0 py-3" type="button">
                            Will I be taxed on my staking rewards?
                          </button>
                        </h2>
                      </div>
                      <!-- Card Body -->
                      <div class="card-body px-0 py-3">
                        US customers who are subject to US tax reporting are required to report their earnings from Staking rewards..
                      </div>
                    </div>

                  </div>
                  <div class="col-12 col-md-6">
                    <div class="card border-0">
                      <!-- Card Header -->
                      <div class="card-header bg-inherit border-0 p-0">
                        <h2 class="mb-0">
                          <button class="btn px-0 py-3" type="button">
                            Difference between a staking wallet and normal wallet?
                          </button>
                        </h2>
                      </div>
                      <!-- Card Body -->
                      <div class="card-body px-0 py-3">
                        This area of Relai specialises only in staking, allowing its developers to give you the best growth/rewards from your locked-in assets.
                        We specialise in institutional staking, pools, and more.
                      </div>
                    </div>

                  </div>
                </div>
                <div class="row justify-content-center">
                  <p class="text-body text-center pt-4 px-3 fw-5">Haven't find suitable answer? <a class="scroll" href="#contact">Tell us what you need.</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ***** FAQ Area End ***** -->

    <!-- ***** Download Area Start ***** -->
    <section class="section download-area overlay-dark ptb_100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-9">
            <!-- Download Text -->
            <div class="download-text text-center">
              <h2 class="text-white">Bridging the Gap between Crypto and Users</h2>
              <p class="text-white my-3 d-none d-sm-block">
                Mining crypto is hard to do on your own. With EarnRelai, it takes just a couple taps
              </p>
              <p class="text-white my-3 d-block d-sm-none">
                Mining crypto is hard to do on your own. With EarnRelai, it takes just a couple taps
              </p>
              <!-- Store Buttons -->
              <div class="button-group store-buttons d-flex justify-content-center">
                <a href="/desktop" target="_self" class="btn btn-bordered mt-4">Go to Wallet</a>
                <!--                            <a href="#">-->
                <!--                                <img src="../../assets/parent/img/icon/google-play.png" alt="">-->
                <!--                            </a>-->
                <!--                            <a href="#">-->
                <!--                                <img src="../../assets/parent/img/icon/app-store.png" alt="">-->
                <!--                            </a>-->
              </div>
              <span class="d-inline-block text-white fw-3 font-italic mt-3">* Best viewed on mobile devices</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ***** Download Area End ***** -->

    <!--====== Contact Area Start ======-->
    <section id="contact" class="contact-area bg-gray ptb_100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8">
            <!-- Section Heading -->
            <div class="section-heading text-center">
              <h2 class="text-capitalize">Speak to us</h2>
              <p class="d-none d-sm-block mt-4">
                Whether you have questions or you would just like to say hello, contact us.
              </p>
              <p class="d-block d-sm-none mt-4">
                Whether you have questions or you would just like to say hello, contact us.
              </p>
            </div>
          </div>
        </div>
        <div class="row justify-content-between">
          <div class="col-12 col-md-5">
            <!-- Contact Us -->
            <div class="contact-us">
              <!--                        <p class="mb-3">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.</p>-->
              <ul>
                <li class="py-2">
                  <a class="media" href="javascript:void(0)">
                    <div class="social-icon mr-3">
                      <i class="fas fa-home"></i>
                    </div>
                    <span class="media-body align-self-center">
                                            <strong>Office 1:</strong> Langstrasse 136, 8004 Zürich, Switzerland <br>
                                            <strong>Office 2:</strong> 20-22 Wenlock Road, London, England, N1 7GU <br>
                                            <strong>Office 3:</strong> Tokyo, Tokyo Japan
                                        </span>
                  </a>
                </li>
                <li class="py-2">
                  <a class="media" href="javascript:void(0)">
                    <div class="social-icon mr-3">
                      <img src="../../assets/parent/img/logo/whatsapp.png" alt="whatsapp logo" style="width: 50px;height:45px">
                    </div>
                    <span class="media-body align-self-center">(+44)7900261731, (+1)9412789632</span>
                  </a>
                </li>
                <li class="py-2">
                  <a class="media" href="mailto:support@earnrelai.com">
                    <div class="social-icon mr-3">
                      <i class="fas fa-envelope"></i>
                    </div>
                    <span class="media-body align-self-center">support@earnrelai.com</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-12 col-md-6 pt-4 pt-md-0">
            <!-- Contact Box -->
            <div class="contact-box text-center">
              <!-- Contact Form -->
              <contact-form/>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--====== Contact Area End ======-->

    <!--====== Height Emulator Area Start ======-->
    <div class="height-emulator d-none d-lg-block"></div>
    <!--====== Height Emulator Area End ======-->
  </div>
</template>

<script>
import contactForm from '../../components/contactForm';
import coinsImg from "../../assets/parent/img/bg/coins.png";

export default {
  name: 'Home',
  data(){
    return {
      coinsImg
    }
  },
  components: {
    contactForm
  }
};
</script>

<style scoped>

@media screen and (min-width: 320px){
  .overlay-dark.custom{
    background-size: contain;
    background-attachment: scroll;
  }
}
@media screen and (min-width: 768px){
  .overlay-dark.custom{
    background-size: 80%;
  }
}
.overlay-dark.custom::after{
  background: none!important;
}
</style>
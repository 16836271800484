<template>
    <div>
        <form id="contact-form" @submit.prevent="proceed">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <input type="text" class="form-control" name="name" placeholder="Name" required="required" v-model="contact.name">
                    </div>
                    <div class="form-group">
                        <input type="email" class="form-control" name="email" placeholder="Email" required="required" v-model="contact.email">
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <textarea class="form-control" name="message" placeholder="How can we help?" required="required" v-model="contact.message"></textarea>
                    </div>
                </div>
                <div class="col-12">
                    <button type="submit" class="btn btn-lg btn-block mt-3"><span class="text-white pr-3"><i class="fas fa-paper-plane"></i></span>Send Message</button>
                </div>
            </div>
        </form>
        <post-contact-message/>
    </div>
</template>

<script>
import Contact from '../models/contact';
import methodsMixin from '../utils/methodsMixin';
import postContactMessage from './modals/home/postContactMessage2';

export default {
    name: 'contactForm',
    data(){
        return {
            contact: new Contact().data
        }
    },
    mixins: [methodsMixin],
    methods: {
        async proceed(){
            if(!this.contact.name || !this.contact.email || !this.contact.message){
                return alert('You are required to complete all fields')
            }
            if(!this.validateEmail(this.contact.email)){
                return alert("Enter a valid email address")
            }
            this.$loader.show();
            const response = await this.$store.dispatch('contact/addContact', this.contact);
            this.$loader.hide();
            if(response.status){
                $('#postContactMSG').modal('show');
                this.contact = new Contact().data;
            }else{
                return alert(response.message)
            }
        }
    },
    components: {
        postContactMessage
    },
};
</script>

<style scoped>

</style>